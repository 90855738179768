<template>
  <div class="m-order-cheque">
    <div v-if="order.type === 'online'">
      <div class="items-common-group">
        <div class="cheque-item">
          <div class="cheque-item__label cheque-item__label-total">
            {{ $t('Products for amount') }}
          </div>
          <div class="cheque-item__value cheque-item__value-total">
            {{ order.total }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label cheque-item__label-weight">
            {{ $t('Order weight') }}
          </div>
          <div class="cheque-item__value cheque-item__value-weight">
            {{ getOrderWeight(order.weight) }} кг
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Shipping') }}
          </div>
          <div class="cheque-item__value">
            <template v-if="isNewPost">
              {{ $t('Delivery Plan') }}
            </template>
            <template v-else>
              {{ order.shipping_total }} грн
            </template>
          </div>
        </div>
        <div
          class="cheque-item"
          v-if="!isNewPost && (order.statusInfo.group === 'fiscalized' || order.status === 'canceled' || order.status === 'collected')"
        >
          <div class="cheque-item__label">
            {{ $t('Package') }}
          </div>
          <div class="cheque-item__value">
            {{ order.packaging_total }} грн
          </div>
        </div>
        <div v-if="parseFloat(order.discount.base)" class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Discount_') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.discount.base)">-</span>
            {{ order.discount.base }} грн
          </div>
        </div>
        <div v-if="parseFloat(order.discount.coupon)" class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Promo code discount') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.discount.coupon)">-</span>
            {{ order.discount.coupon }} грн
          </div>
        </div>
      </div>
      <div v-if="order.statusInfo.group === 'non-fiscalized'">
        <div class="cheque-item">
          <div class="cheque-item__label cheque-item__value-amount">
            {{ $t('Total amount') }}
          </div>
          <div class="cheque-item__value cheque-item__value-amount">
            {{ order.amount }} грн
          </div>
        </div>
      </div>
      <div v-if="order.statusInfo.group === 'fiscalized'">
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Bonuses accrued') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.bonus_credit)">+</span>
            {{ order.bonus_credit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Bonuses spent') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.bonus_debit)">-</span>
            {{ order.bonus_debit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Money accrued') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.money_credit)">+</span>
            {{ order.money_credit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Money spent') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.money_debit)">-</span>
            {{ order.money_debit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label cheque-item__label-amount">
            {{ $t('Paid up') }}
          </div>
          <div class="cheque-item__value cheque-item__value-amount">
            {{ order.amount }} грн
          </div>
        </div>
      </div>
    </div>
    <div v-if="order.type === 'offline'">
      <div class="items-common-group">
        <div class="cheque-item">
          <div class="cheque-item__label cheque-item__label-total">
            {{ $t('Products for amount') }}
          </div>
          <div class="cheque-item__value cheque-item__value-total">
            {{ order.total }} грн
          </div>
        </div>
      </div>
      <div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Bonuses accrued') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.bonus_credit)">+</span>
            {{ order.bonus_credit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Bonuses spent') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.bonus_debit)">-</span>
            {{ order.bonus_debit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Money accrued') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.money_credit)">+</span>
            {{ order.money_credit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label">
            {{ $t('Money spent') }}
          </div>
          <div class="cheque-item__value">
            <span v-if="getShowSign(order.money_debit)">-</span>
            {{ order.money_debit }} грн
          </div>
        </div>
        <div class="cheque-item">
          <div class="cheque-item__label cheque-item__label-amount">
            {{ $t('Paid up') }}
          </div>
          <div class="cheque-item__value cheque-item__value-amount">
            {{ order.amount }} грн
          </div>
        </div>
      </div>
    </div>
    <div v-if="order.address" class="cheque-address">
      {{ order.address }}
    </div>
    <div
      v-if="reorderAvailable"
      class="m-order-cheque__reorder"
    >
      <MProductsAddToCart
        class="details-button"
        :title="$t('Repeat order')"
        :products="inStockProducts"
      />
    </div>
  </div>
</template>

<script>
import MProductsAddToCart from 'theme/components/molecules/m-products-add-to-cart.vue';

export default {
  name: 'MAccountOrderCheque',
  components: {
    MProductsAddToCart
  },
  data () {
    return {}
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    existingProducts: {
      type: Array,
      default: () => []
    },
    isNewPost: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    reorderAvailable () {
      return ['online', 'offline'].includes(this.order.type) && this.inStockProducts.length
    },
    inStockProducts () {
      return this.existingProducts.filter(e => e.stock?.is_in_stock) || []
    }
  },
  methods: {
    getShowSign (value) {
      return parseFloat(value)
    },
    getOrderWeight (value) {
      if (typeof value !== 'number') {
        return value
      }
      return (value / 1000).toFixed(1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-order-cheque {
  margin-top: var(--spacer-25);
  font-family: var(--font-family-secondary);
  color: var(--black);

  @media (max-width: $mobile-max) {
    padding-top: var(--spacer-25);
    border-top: 1px solid var(--gray3);
  }

  .cheque-header {
    font-size: var(--font-size-16);
    font-weight: var(--font-medium);
    line-height:var(--font-size-17);
    margin-bottom: var(--spacer-16);
  }

  .cheque-item {
    margin-top: var(--spacer-10);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
      font-size: var(--font-size-16);
      line-height:var(--font-size-17);
    }

    &__value {
      font-size: var(--font-size-16);
      line-height:var(--font-size-19);
    }

    &__label-total,&__value-total {
      font-size: var(--font-size-16);
      line-height:var(--font-size-19);
      font-weight: var(--font-medium);
    }

    &__label-weight,&__value-weight {
      color: var(--dark-gray);
      font-size: var(--font-size-14);
      line-height:var(--font-size-17);
      font-weight: var(--font-normal);
    }

    &__label-amount,&__value-amount {
      margin-top: var(--spacer-5);
      font-size: var(--font-size-20);
      line-height:var(--font-size-25);
      font-weight: var(--font-medium);
    }
  }

  .items-common-group {
    .cheque-item:first-of-type {
      margin-top: 0;
    }
  }

  .cheque-address {
    margin-top: var(--spacer-15);
    font-size: var(--font-size-14);
    line-height:var(--font-size-17);
    color: var(--dark-gray);
  }

  &__reorder {
    margin-top: var(--spacer-25);
  }
}
</style>
